@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";
.filterFieldContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 0 auto;
    width: auto;
    justify-content: flex-start;
    @media (max-width: @screen-sm) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.filter {
    flex: 1 0 auto;
    max-width: 800/16rem;
}

.filterField {
    width: 100%;
    & > div {
        width: 100%;
    }
}

.showHideEmptyGroups {
    align-self: center;
}

@media (max-width: @screen-sm) {
    .filterFieldContainer,
    .filter {
        flex-direction: column;
        width: 100%; // Needed for mobile.
        margin: 0 0.5rem;
    }
}

.actionContainer {
    display: flex;
    align-items: center;
}

.actionContainer > :not(:last-child) {
    margin-right: 0.5rem !important;
}

.groupHeader {
    display: flex;
    align-items: center;
    background: @groupHeaderBackground;
    color: @groupHeader;
    overflow: hidden;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    font-size: 17/16rem;
    justify-content: space-between;
}
.groupName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.groupDescription {
    padding: 0 15/16rem;
}

.lifecycleMap {
    margin-top: 1rem;
}

.info {
    color: @secondaryText;
}

.loadMoreContainer {
    padding: 1rem;
}
.loadMoreActions {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
}
.loadMoreSubText {
    font-size: 0.75rem;
    color: @secondaryText;
}
.loadMoreHeaderInfo {
    padding: 0.5rem 1rem;
    color: @secondaryText;
    font-size: 0.875rem;
}
.eapAction {
    margin-right: 1rem;
}
.spacer {
    height: 8px;
}
