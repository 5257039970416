@import "../../../resources/styles/fonts.less";

.text {
  vertical-align: baseline !important;
  width: 100% !important;
  min-width: 0 !important;
}

.container {
  width: 100%;
}

.monospacedText {
  :global(.MuiInputBase-root) {
    .monospace;
    font-size: @monospace-font-size;
  }
}

.noUnderline {
  border-bottom: 0;
  div::before { // This isn't great because it relies on the structure of MUI's TextField component, but it's the only way I could find to override this here.
    border-bottom: 0;
  }
  div::after { // This isn't great because it relies on the structure of MUI's TextField component, but it's the only way I could find to override this here.
    border-bottom: 0;
  }
}