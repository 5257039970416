@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";
@import "../../../../../resources/styles/fonts";

.taskSummaryTopLevel {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.taskSummaryChildren {
  margin-left: 2rem;

  .taskSummaryLineText {
    font-weight: normal;
  }
}

.taskSummaryLine{
  display: flex;
  margin: 1rem 0.5rem;
  align-items: center;
  color: @primaryText;
}

.taskSummaryLineText {
  display: inline-block;
  padding-left: 0.5rem;
  color: @primaryText;
  &:hover {
    color: @primaryText;
  }
}

.summaryIcon {
  width: 1rem;
  height: 1rem;
  font-size: 1rem!important; // Anything less than 1rem and the rotation animation will distort (on fa-spinner icons).
  text-align: center;
}

.artifactsBlock {
  margin-left: 2.75rem;
}

.highlightBlock {
  margin-left: 2.75rem;
  margin-bottom: 0.5rem;
  background-color: @infoBackground;
  padding: 0.5rem;
  color: @primaryText;
}

.fatalBlock {
  color: @dangerText;
  margin-left: 2.75rem;
  
  span {
    .monospace;
    font-size: 0.75rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}

.retryBlock {
  margin-left: 2.75rem;
  color: @primaryText;
}

.primaryBackground {
  background: @primaryBackground;
}

.jumpToLine {
  .jumpToLineLink {
    margin-left: 1rem;
    font-size: 0.8rem;
    visibility: hidden;
    transition: all 0.1s linear;
    display: inline-block;
  }

  &:hover .jumpToLineLink {
    transform: translateX(5px);
    visibility: visible;
  }
}
