@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";

.note {
    font-family: Roboto;
    font-size: 0.75rem;
    text-align: left;
    font-weight: @normal;
    margin-bottom: 0.5rem;
    color: @ternaryText;
    a {
        color: @mainText;
    }
    .checkboxNote {
        display: none;
    }
}

.header {
    font-size: 0.75rem;
    font-weight: 600;
    color: @ternaryText;
}
