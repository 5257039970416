@import "../../../resources/styles/variables";
@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";

.warningFormExpander {
    .formSectionBase();
    .formSectionBorder();
    border-bottom: none;
    // mark.siedle - Oh God, these are hidden away deep in material-ui #plsKillMe
    > div:first-child {
        padding: 0!important;
    }
}

.formSectionBase() {
    width: 100%;
    background-color: transparent !important;
    box-shadow: none!important; //swap the box-shadow of the material-ui control for a border
}

.formSectionBorder() {
    border-radius: 0!important;
    border-style: solid;
    border-width: 0.0625rem 0 0 0;
    border-color: @dividerLight;
}

.formBorder {
    .formSectionBorder();
}

.formExpander, .formNonExpander {
    .formSectionBase();
    .formSectionBorder();
    border-bottom: none;
}

.formSection {
    .formSectionBase();
}

.cardTitleContainer {
    display: flex;
    align-items: top;
    word-wrap: break-word; // mark.siedle - Needed on settings page for long setting keys to wrap properly.
    padding: 0.75rem 1rem;
    font-size: 	1.5rem;
    color: @primaryText!important;
    line-height: 2.25;
    position: relative; // Needed because we absolutely position the expand/collapse icon in this container.
    flex-direction: row;
    flex-grow: 1;
    @media (max-width: @screen-md) {
        flex-direction: column;
    }
}

.clickable {
    cursor: pointer;
    display: flex;
    flex-direction: row;;
    &:hover {
        background-color: @hover;
    }
}

.expandableFormSectionTitleWithChip {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.cardTitleContainerForceMobile {
    .cardTitleContainer;
    display:block;
    padding: 0rem 1rem 1rem;
}

.cardTitle {
    top: 1rem;
    width: 15rem !important;
    min-width: 15rem !important;
    min-height: 1.5rem !important;
    line-height: 1.5rem !important;
    font-family: Roboto !important;
    font-size: 1rem !important;
    font-weight: @bold !important;
    text-align: left !important;
    color: @primaryText !important;
    @media (max-width: @screen-md) {
        text-align: left !important;
        position: static !important;
    }
}
.cardTitleForceMobile {
    .cardTitle;
    text-align: left !important;
    position: static !important;
}

.cardSubTitle {
    font-family: Roboto !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    text-align: left !important;
    color: @primaryText !important;
    margin-left: 1.6rem !important;
    margin-right: 2rem !important;
    display: block !important;
    flex-grow: 1;
    word-break: break-word; // Needed to prevent issues when sidebar is open
    min-width: 50%; // ie and firefox require flex children to have a min-width for word-wrap to work https://stackoverflow.com/questions/36150458/flex-item-overflows-container-due-to-long-word-even-after-using-word-wrap
    @media (max-width: @screen-md) {
        margin-left: 0rem !important;
    }
    > div > p { // our markdown renderer wraps a div then a p around text which has a bunch of top/bottom margin
        margin: 0;
    }
}

.cardSubTitleForceMobile {
    .cardSubTitle;
    margin-left: 0rem !important;
}

.cardSubTitleWithOverflowMenu {
    margin-right: 3rem !important;
}

.cardPlaceholderSubTitle {
    &:extend(.cardSubTitle);
    color: @secondaryText !important;
}

.cardDefaultSubTitle{
    &:extend(.cardSubTitle);
}

.cardDefaultSubTitle::after {
    content: " (default)";
    color: @secondaryText !important;
}

.cardMedia {
    margin-left: 17.6rem;
    margin-right: 4rem;
    padding: 0.25rem 0 1rem 0 !important; // Overriding MUI padding.
    max-width:37.5rem;
    @media (max-width: @screen-md) {
        margin-left: 1.1rem !important;
        margin-right: 1.1rem;
    }
}
.cardMediaForceMobile {
    .cardMedia;
    margin-left: 1.1rem !important;
    margin-right: 1.1rem;
}

.cardMediaFillRight {
    max-width: none;
    container-type: inline-size;
}

.cardMediaFillAll {
    margin-left: 0;
    margin-right: 0;
}

.cardMedia .container {
    padding-bottom: 0.5rem !important;
}

.unstructuredFormContent
{
    //using !important to override .MuiCardContent-root:last-child { padding-bottom: 24px; }
    padding: 1rem !important;
    color: @primaryText;
}

.expandLink {
    width: 6rem;
    height: 1.19rem;
    font-family: Roboto;
    font-size: 13/16rem;
    line-height: 1.36rem;
    color: @secondaryText;
    margin-right: 1.5rem;
}

.expandLink:hover {
    color: @mainText;
}

.linkContainer {
    width: 100%;
    text-align: right;
}

.sectionHeading {
    width:100%;
    background: @secondaryBackground;
    .formSectionBorder();
    margin: 0 0 -0.0725rem 0;
}

.dialogSectionHeading {
    background: @secondaryBackground;
    margin: 2rem -1rem 1rem;
    border-style: solid;
    border-width: 0.0625rem 0;
    border-color: @dividerLight;
}

.sectionHeadingTitle {
    padding: 0.6rem 1rem;
    font-size: 17/16rem;
    color: @primaryText;

    // if there is another child, we must have a description
    &:not(:last-child) {
        padding-bottom: 8/16rem;
    }
}

.sectionHeadingDescription {
    padding: 0 1rem 0.6rem 1rem;
    font-size: 14/16rem;
    color: @ternaryText;
}

.overflowMenuActions {
    margin-left: auto;
    padding-right: 2.25rem;
    align-items: center;
}
.cardExpander {
    display: flex;
    justify-content: flex-end;
    align-content: flex-start;
}
.expandIcon, .expandIcon:hover {
    background-color: transparent!important;
}

.expandClosed {
    transform: rotate(0deg)!important;
}
.expandOpen {
    transform: rotate(180deg)!important;
}

.stretchContent {
    padding: 0!important;
    margin: 0!important;
    div {
        border-color: @transparent;
    }
}

.contextualHelpContainer {
    display: inline-block;
    position: relative;
    margin-left: 4px;
    top: -1px;
}