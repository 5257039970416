@import "../../../../resources/styles/media";

.container {
    margin: 0.75rem 2rem 0 2rem;
}

.paperContainer {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    @media (max-width: @screen-sm) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.unorderedList {
    list-style-type: disc;
    padding-left: 2rem;
}
.layoutContainer {
    margin-top: 1rem;
}
