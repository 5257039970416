@import "../../resources/styles/colors";
@import "../../resources/styles/fonts";
@import "../../resources/styles/media";
@import "../../resources/styles/variables";
@import "../../resources/styles/text";

.logLine {
  @media (max-width: @screen-sm) { /* mark.siedle - Flex hackery to get this working for mobile */
    flex-wrap: wrap;
    > div {
      width: 100% !important;
    }
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  animation: fadeIn 0.3s forwards;
  opacity: 0;
  
  &.selected {
    background: @alertTaskLogBackground;
    .lineNumber {
      color: @primaryText;
    }
    border-radius: 2px;
  }

  &:hover {
    background: @infoBackground;
    border-radius: 2px;
  }

}

.copyHelper {
  .lineNumber {
    .userSelect;
  }

  .copyHelperToken {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.topLevelBlock {
  margin: 1rem;
}

.messages {
  padding: 1rem;
}

.message {
  white-space: pre-wrap;
  word-break: break-word; /* mark.siedle - Needed or this can push out containers and stop sidebars from wrapping properly. */
  min-width: 10rem; /* mark.siedle - Needed or log lines can compress text to the extreme :) */
  width: 100%;
}

.category {
  min-width: 6rem;
  width: 6rem;
  display: inline-block;
  margin: 0 10px;
  text-align: center;
  overflow: hidden;
  color: @secondaryText;
}

.lineNumber {
  .noUserSelect;
  white-space: pre;
  word-break: keep-all;
  color: @secondaryText;
  text-align: right;
  min-width: 3rem;
  width: 3rem;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

.occurred {
  white-space: pre;
  word-break: keep-all;
  min-width: 16rem;
  width: 16rem;
  overflow: hidden;
  color: @secondaryText;
}

.gap {
  .message {
    position: relative;
    font-style: italic;
  }

  .logLine:hover {
    background: none;
  }
  
  .showMore {
    display: block;
    
    &.disabled {
      color: @linkText;
      cursor: not-allowed;
    }
  }
}

.element {
}

.elementError {
  .element;
  
  .message, .category {
    color: @dangerText;
    font-weight: @bold;
  }
}

.elementWarning {
  .element;
  .message, .category {
    color: @alertText;
    font-weight: @bold;
  }
}

.elementHighlight {
  .element;
  
  .message {
    color: @infoText;
    font-weight: @bold;
  }
}

.elementWait {
  .element;
  
  .message {
    color: @infoText;
  }
}

.elementVerbose {
  .element;

  .message {
    color: @secondaryText;
  }
}

.elementInfo {
  .element;
  
  .message {
    color: @primaryText;
  }
}

.elementRetry {
  display: none;
}
