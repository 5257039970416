@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";

.onboardingPanelOuterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardContainer {
  padding: 0 1rem 0 1rem;

  @media (max-width: @screen-md) {
    padding: 0;
  }
}

.filterHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end; //baseline; // mark.siedle - baseline screws up when you use our select lists for some reason :/
  flex: 1 0 auto;
  width:100%;
  @media (max-width: @screen-sm) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.filterFieldContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end; //baseline; // mark.siedle - baseline screws up when you use our select lists for some reason :/
  flex: 1 0 auto;
  width:100%;
  justify-content: flex-start;
  margin-bottom: 1rem;
  @media (max-width: @screen-sm) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.filter {
  flex: 1 0 auto;

  @media (max-width: @screen-sm) {
    flex-direction: column;
  }
}

.filterField {
  width:100%;
  max-width: 24rem !important; // needed to override the .paper div:first-child selector above
  &>div{
    width: 100%;
  }
}

.matchCount {
  margin-bottom: 0.75rem;
  font-size: 0.875rem;

  @media (max-width: @drawerBreakpointWidth) {
    flex-direction: column;
    width: 100%;
  }
}

.failingDeploymentsNote {
  margin-bottom: -0.125rem;
}

.alert {
  color: @alert;
}

.info {
  color: @secondaryText;
}
