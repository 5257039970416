@import "../../resources/styles/colors";
@import "../../resources/styles/media.less";

.actionList {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    @media (max-width: @screen-sm) {
        flex-wrap: wrap;
    }
}

.alignStart {
    justify-content: flex-start;
}

.alignEnd {
    justify-content: flex-end;
}