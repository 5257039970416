@import "../../resources/styles/colors";
@import "../../resources/styles/media";
@import "../../resources/styles/variables";

@externalMargin: 1rem;

.container {
    padding-left: 16px;
    padding-right: 16px;
}

.categoryTemplates > div,
.categories > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@media (max-width: @screen-sm) {
    .categoryTemplates > div {
        flex-direction: column;
    }
}

.categoryName {
    color: @mainText;
    text-align: center;
    margin-top: 13/16rem;
    margin-bottom: 13/16rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95/16rem;
}

.actions {
    text-align: right;
    margin-right: @externalMargin;
}

.onHoverActions {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    .heading,
    .logo,
    .content {
        opacity: 0.7;
    }
}

.onHoverActionsBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @cardHover;
    opacity: 0.5;
}

.onHoverPrimaryActions {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    position: relative;
}

.onHoverPrimaryActionsCursor {
    cursor: pointer;
}

.primaryAction {
    position: relative;
    flex: 4;
}

.secondaryAction {
    position: relative;
    background-color: @paper2;
    padding-bottom: 8/16rem;
    padding-top: 8/16rem;
    width: 100%;
    text-align: center;
}

.empty {
    flex: 3;
}

.templateListTitle {
    background-color: @secondaryBackground;
    border-top: dashed @dividerLight 0.0625rem;
    border-bottom: dashed @dividerLight 0.0625rem;
    padding: 8/16rem 18/16rem;
    margin-right: 1rem;
}

.stepTemplatesHeader {
    margin-bottom: 1rem;

    h4 {
        color: @primaryText;
        font-size: 1rem;
        font-weight: @normal;
        line-height: 1.5rem;
        padding-left: 0;
    }
}

.stepTemplatesSection {
    padding-left: 0;
}

.stepTemplatesNoResult {
    color: @ternaryText;
    font-size: 0.875rem;
    font-weight: @normal;
    line-height: 1.5rem;
}
