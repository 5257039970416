@import "../../../resources/styles/colors.less";
@import "../../../resources/styles/fonts.less";

.addRowsContainer {
    width: 100%;
    height: 100%;
}

.addRowBackground {
    background-color: @primaryBackground;
}

.controlCell {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 100%;
}

.scopeCell {
    height: 100%;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem;
    border-bottom: 2px solid @dividerLight;
    background-color: @paper1;
}

.scopeWrapper {
    flex: 1 1 auto;
}

