@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables.less";

.card-list {
    flex-direction: row;
    display: flex;
    justify-content: space-around;

    .overview-item {
        display: flex;
        align-items: flex-start;
        width: 25%;
        padding-bottom: 1rem;

        .overview-count,
        .overview-description {
            display: flex;
            font-size: 1rem;
            text-align: left;
        }

        .overview-count {
            font-weight: @bold;
            margin-bottom: 0.5rem;
            font-size: 1.1rem;
        }
    }
}

.usageTable {
    th {
        font-weight: @bold;
    }

    td {

        font-weight: @light;

    }
    margin-bottom: 2rem;
}