@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";
@import "../../../../../resources/styles/variables";

.container {
    margin-top: 1rem; // To give us some space away from the filter box.
    margin-bottom: 2.5rem;
    padding-left: 16px;
    padding-right: 16px;
}

.categories {
}

.actionContainer {
    display: flex;
    justify-content: flex-end;
}

.sectionDivider {
    font-size: 17/16rem;
}

.categoryTemplates > div,
.categories > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > * {
        margin-right: 0.5rem !important;
        margin-bottom: 0.5rem !important; // margin at lower resolutions.
    }
}

.categoryTemplates li {
    display: flex;
    height: 100%;
}

.stepTemplatesHeader {
    margin-bottom: 1rem;
    color: @primaryText;
    font-size: 1rem;
    font-weight: @normal;
    line-height: 1.5rem;

    h4 {
        color: @primaryText;
        font-size: 1rem;
        font-weight: @normal;
        line-height: 1.5rem;
        padding-left: 0;
    }
}

.stepTemplatesNoResult {
    color: @ternaryText;
    font-size: 0.875rem;
    font-weight: @normal;
    line-height: 1.5rem;
}

@media (max-width: @screen-sm) {
    .templates,
    .categoryTemplates > div {
        flex-direction: column;
    }
}
