@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

.section {
  margin-top: 1rem;
}

.results {
  .box {
    padding: 0.625rem;
    align-self: stretch;
  }

  .title {
    margin-bottom: 0.625rem;
  }

  .success {
    background-color: @successHighlight;
    .box;
    .title {
      color: @successText;
    }
  }

  .warning {
    background-color: @dangerHighlight;
    .box;
    .title {
      color: @dangerText;
    }
  }

  .none {
    .box;
  }
}

.stepPreview {
  .step {
    padding-bottom: 0.5rem;
  }
  .stepPreviewTitle {

  }

  .skipStepPreviewTitle {
    text-decoration: line-through;
  }

  .actionSummary {
    display: flex;
    flex-flow: row;
    padding-top: 0.875rem;
  }

  .logo {
    order: 1;
    display: flex;
    align-items: center;
    span {
      margin-left: 0.3rem;
    }
  }

  .machines {
    padding-left: 1rem;
  }

  .content {
    flex: 4 0;
    order: 2;
    margin-left: 0.5rem;
  }
}

.stepToggle {
  .logo {
    order: 1;
    display: flex;
    align-items: center;
    span {
      margin-left: 0.3rem;
    }
  }

  .note {
    margin-bottom: 2rem;
  }

  .stepCheckboxes {
      margin-top: -1rem;
  }
}

.filter {
  max-width: 16rem;
}

.excludedAction {
  text-decoration: line-through;
}
.excludedActionPostfixLabel {
  color: @secondaryText;
}
.creatingDeploymentDialogContent {
  padding-bottom: 1.75rem;
}
.freezeList {
  list-style-type: disc;
  margin-left: 2rem;
}

.freezeTableContainer {
  padding-top: 8px;
  padding-bottom: 8px;
}

.freezeTableHeaderRow {
  border-top: 0.0625rem solid var(--colorCalloutBorderInfo);
  &:last-child {
      border-bottom: 0.0625rem solid var(--colorCalloutBorderInfo);
  }
}

.freezeTableCell {
  padding-top: 0.3rem;
  padding-bottom: 0.1rem;
  padding-left: 0;
  vertical-align: middle;
}

.freezeTableText {
  vertical-align: middle;
}