@import "../../../resources/styles/colors";

.container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.separator {
  margin: 0 0.425rem 0 0.125rem;
  color: @primaryText;
}

.error {
  color: @dangerText;
  font-size: 0.75rem;
  margin-top: 0.125rem;
}
.digit {
  width: 3rem;
  margin-right: 0.75rem;
}