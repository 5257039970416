@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";
.group {
    margin-top: 4rem;
}

.deployAllCell {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deploy {
    text-transform: uppercase;
    text-decoration: none;
    color: @mainText;
    font-size: 0.875rem;
}

.dashboardTable {
    td {
        border-bottom: dashed 0.0625rem @dividerLight; // Dashed to simulate basic conveyor-belt concept.
    }
}

.border {
    border-bottom: dashed 0.0625rem @dividerLight; // Dashed to simulate basic conveyor-belt concept.
}

.spacerCell {
    .border;
    width: 100%;
}

.headerChip {
    font-weight: normal;
    padding-top: 0.25rem;
}

.headerCell {
    font-size: 1.0625rem;
    padding: 1rem 1.125rem 1rem 1rem; // Slightly more padding-right to deal with potential vertical scrollbar that can appear for dashboard data.
    font-weight: @semibold;
    word-break: break-word;
    color: @primaryText;
    a {
        font-weight: @semibold;
    }
}

.columnHead {
    font-size: 1.0625rem;
    padding: 1rem 1.125rem 1rem 1rem;
    font-weight: @normal;
    display: flex;
    color: @primaryText;
}

.highlightColumn {
    font-weight: @bold;
    padding-left: 1rem;
    display: flex;
}

.centerCell {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    min-width: 12.5rem;
    max-width: 18.75rem;
    word-wrap: break-word;
    word-break: break-all; // Needed for super long versions, since we now have fixed width dashboard cells.
}

.fullHeight {
    height: 100%;
}

.blankCell {
    width: 0.0625rem;
    height: 5rem;
}

.container {
    overflow: hidden;
    margin-bottom: 1rem;
}

.scrollableContainer {
    flex-grow: 1;
    overflow-x: auto;
}

.emptyCell {
    padding: 1rem;
    width: 100%;
    font-size: 0.875rem;
}

.headersOverflowContainer {
    z-index: 2;
    position: absolute;
    background-color: @whiteConstant;
    overflow-x: auto;
}

.headersContainer {
    display: flex;
    flex-direction: row;
}

.dataContainer {
    display: flex;
    flex-direction: row;
    position: relative;
}

.environmentContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
}

.firstColumnContainer {
    display: flex;
    flex-direction: column;
    z-index: 3;
}

.scrollbarPadding {
    height: 20px;
}

.hideScrollbarPanel {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: @whiteConstant;
    width: 100%;
    height: 20px;
}

.rightContainer {
    overflow-x: hidden;
    flex-grow: 1;
}

.scrollableTableContainer {
    display: flex;
}

.innerGrid {
    box-sizing: content-box !important;
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    padding-right: 1rem;
    padding-bottom: 1rem;
    -ms-overflow-style: none;
    &:focus {
        outline: none; // overrides default browser styles that makes the whole table act focus-able
    }
}

.innerGrid::-webkit-scrollbar {
    display: none;
}

.loadMoreContainer {
    padding: 0 1rem 1rem 1rem;
}
.loadMoreActions {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
}
.loadMoreSubText {
    font-size: 0.75rem;
}
