@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/fonts";

.rawLogContent {
  .monospace;
  font-size: 12px;
  padding: 1rem;
  margin: 1rem;
  word-break: break-all;
  word-wrap: break-word;
  background-color: @secondaryBackground;
  border: 0.0625rem solid @dividerLight;
  border-radius: 0.125rem;
  white-space: pre;
  overflow-x: scroll;
  text-rendering: optimizeSpeed;
}
