@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.lists {
  display: flex;
  @media (max-width: @screen-sm) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; // Need this to wrap for mobile.
  }
}

.list {
  flex: 50%;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.listHeader {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
  h4 {
    margin: 0.5rem 0;
  }
}
.templateItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.templateItemName {
  flex: 2;
  margin-left: 1rem;
  margin-right: 1rem;
}

.importNote, .exportNote {
  margin-top: 1rem;
}

.warning {
  margin: 1rem 0;
}

.headerColumn:first-child {
  width: 20%;
}

.licence {
  text-align: right;
  margin-right: 1rem;
}

.summary {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 1rem;
}

.divider {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.icon {
  padding-left: 0.5rem;
}

.mergeTabs {
  display: flex;
  flex-direction: row;

  .navTabs {
    background-color: @alertHighlight;
    color: @alert;
    background-size: 20px 20px;
    border-radius: 2px;
    padding: 10px;
  }

  .green {
    background-color: @successHighlight;
    color: @successText;
    background-size: 20px 20px;
  }

  .active {
    .showDetails {
      visibility: hidden;
    }

    .navTabs {
      background-color: @alertBackground;
      color: @whiteConstant;

      a {
        color: @whiteConstant;
      }
    }

    .green {
      background-color: @successBackground;
      color: @whiteConstant;

      a {
        color: @whiteConstant;
      }
    }
  }
}

.mergeTabs > * {
  margin: 0.5rem;
  flex-grow: 1;
}

.mergeTabs > *:first-child {
  margin-left: 0;
}

.mergeTabs > *:last-child {
  margin-right: 0;
}

.reasons {
  margin: 0;
}

.filterSearchBoxContainer {
  width: 320px; // No larger than 320 for mobile
}

.noBuiltInSteps {
  padding: 0 1rem;
}