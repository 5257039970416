.environmentChips {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.projectNameCell {
    width: 30%;
}

.overflowMenu {
    display: flex;
    justifyContent: flex-end;
}
