@import "../../../../../../resources/styles/colors";
@import "../../../../../../resources/styles/variables.less";

.box {
    padding: 0.375rem 0.5rem;
    align-self: stretch;

    font-size: 14/16rem;
    color: @primaryText;
    border-radius: 4px;
}

.waiting {
    background-color: @infoBackground;
    .box;
}

.success {
    background-color: @successCalloutBackground;
    .box;
}

.failure {
    background-color: @dangerCalloutBackground;
    .box;
}

.none {
    .box();
}

.contentContainer {
    display: flex;
    align-items: flex-start;
    gap: 0.375rem;
}

.icon {
    margin-right: 0.25rem;
}

.waiting-icon {
    height: 0.75rem;
    width: 0.75rem;
    .icon;
}

.iconContainer {
    padding: 2px;
}
