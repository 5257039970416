@import "../../resources/styles/colors";

.frozen-icon {
        box-sizing: border-box;
        background: @darkBackground;
        width: 1rem;
        height: 1rem;
        display: inline-flex!important;
        align-items: center;
        justify-content: center;
        color: @iconLight;
        border-radius: 2/16rem;
        font-size: 3/4rem;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
}

.dashboard-icon {
        margin-left: 1/2rem;
}