@import "../../resources/styles/variables";
@import "../../resources/styles/media";
@import "../../resources/styles/colors";

@cardPadding: 1rem;
@cardWidth: 13.75rem - @cardPadding;
@cardHeight: 15.7rem - @cardPadding;

.container {
    width: @cardWidth;
    padding: @cardPadding @cardPadding 0 @cardPadding;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 auto;
    box-shadow: @shadowSmall;
    border-radius: 8px;
    overflow: hidden;
    color: @primaryText;
    text-decoration: none;
    background: @paper2;
    transition-property: background-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;

    &:active,
    &:visited {
        color: @primaryText;
        text-decoration: none;
    }

    &:hover,
    &:focus-within {
        box-shadow: @shadowLarge;
        background-color: @cardHover;

        .onHover {
            opacity: 1;
        }
    }

    &:focus-within {
        border: solid white 1px;
    }
}

.containerMargin {
    margin: 0.8rem;

    @media (max-width: @screen-sm) {
      margin: 0.8rem 0;
    }
  }

.onHover {
    position: absolute;
    flex-direction: column;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.1s ease-in;
}

.header {
    max-width: @cardWidth - (@cardPadding * 0.5);
    font-size: 1.0625rem;
    margin-top: 1rem;
    text-align: center;
    color: @primaryText;
    font-weight: 400;
    word-wrap: break-word;
    word-break: break-word;
}
.headerNoLogo {
    max-width: @cardWidth - (@cardPadding * 0.5);
    font-size: 1.0625rem;
    margin-top: 0;
    text-align: center;
    color: @primaryText;
    font-weight: 400;
    word-wrap: break-word;
    word-break: break-word;
}

// the link should cover the entire card
.link > a {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.disabled * {
    color: @secondaryText;
}

.content {
    text-align: center;
    max-width: @cardWidth - (@cardPadding * 0.5);
    color: @ternaryText;
    font-size: 0.8125rem;
    font-weight: @light;
    line-height: 1rem;
    max-height: 4.5rem;
    margin-bottom: 1rem;
    height: 100%;

    //This will all need to go/change when real markdown content is added
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    font-weight: @light;
    p {
        margin: 0.5rem 0; // This helps us get descriptions that line up nicely with 4 lines of text.
    }
}
/* .contentFade {} //TODO Need a better solution for the fade
.contentFade:after {
    position: absolute;
    bottom: -1rem;
    height: 3rem;
    width: 100%;
    content: "";
    background: linear-gradient(to top, rgb(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    left: 0;
    z-index: 1;
    overflow: hidden;
} */

.footer {
    margin-top: auto;
    width: @cardWidth;
    font-size: 10/16rem;
    padding-top: 8/16rem;
    padding-bottom: 8/16rem;
    text-align: center;
    @media (max-width: @screen-sm) {
        justify-content: center;
    }
}

.footerIcon {
    margin-left: 3/16rem;
    @media (max-width: @screen-sm) {
        margin-left: 0;
    }
}

@media (max-width: @screen-sm) {
    .content {
        display: none;
    }

    .header {
        margin-top: auto;
        margin-bottom: auto;
        max-width: none;
        flex: 1 1 auto;
        text-align: left;
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        padding: @cardPadding - 0.5rem;
        height: auto;
        width: 100%;
    }

    .footer > * {
        display: none;
    }

    .footer {
        padding: 0;
        margin: 0;
        width: 1.5rem;
        height: auto;
        display: flex;
        align-items: center;
    }

    .footerIcon {
        display: block;
    }
}

.cardLeft {
    min-width: 20rem;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    > .footer,
    > .content,
    > .header,
    > .headerNoLogo,
    > .logo {
        text-align: left;
        width: 100%;
        align-self: flex-start;
        max-width: 100%;
        margin-top: 0;
    }
}

.cardLeft .header {
    margin-top: 9px;
}
