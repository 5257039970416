@import "../../../../../resources/styles/colors";

.feedbackSubtitle {
    font-size: 0.75rem;
    font-weight: 300;
    padding: 0rem 1rem;
    line-height: 1rem;
    color: @ternaryText;
}

.fullHeight {
    height: 100%;
}
