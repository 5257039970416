@import "../../resources/styles/colors";
@import "../../resources/styles/media";
@import "../../resources/styles/variables";

.devBanner {
    background-color: @infoBackground;
    color: @infoText;
    font-size: 13/16rem;
    @media (max-width: @screen-md) {
        height: unset;
    }
    display: flex;
    flex-direction: row;
    font-weight: @normal;
}

.closeBanner {}

.entries {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (max-width: @screen-md) {
        flex-direction: column;
        align-items: flex-start;
    }
    .entry {
        padding: 0 1rem;
        border-right: 2/16rem solid @infoText;
        @media (max-width: @screen-md) {
            border-right: unset;
        }
    }
    > :last-child{
        border-right: unset;
    }
}