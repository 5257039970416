.row{
  font-size: 0.75rem;
  padding: 0.5rem !important;
}

.whoColumn {
  min-width: 3rem;
  max-width: 11rem;
  word-break: break-all;
}

.container {
  margin-bottom: 2rem;
}