@import "../../../resources/styles/colors.less";

.markdownEditorContainer {
    position: relative;
    width:100%;
}

.markdownControlsContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    button {
        margin-right: 8px;
    }
}

.restrictedHeight {
    max-height: 15rem;
}

.markdownSupported{
    padding-right: 0.25rem;
}

.iconButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background: @secondaryBackground;
    border: 0.0625rem solid @divider;
    border-bottom: 0;
}

.textarea {
    max-width: inherit !important
}

.markdownControlsLink {
    font-size: 0.75rem;
    font-weight: 300;
}